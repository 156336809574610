import React from 'react'
import { t } from 'i18next'

import {
  Input,
  Icon,
  ColorPicker,
  Spacing,
  Text,
  Tooltip,
  EmployeeList,
  Dropdown
} from '@ui'
import {
  errorUtil,
  formUtil,
  valUtil,
  colorUtil,
  miscUtil,
  notification,
  sortUtil
} from '@app/util'

import { CapacityPlanning } from './CapacityPlanning'
import { Layout } from './Layout'
import connect from './connect'
import moment from 'moment'

class _PositionEditor extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.setData()
    this.state = {
      form: {
        name: null,
        color: null,
        assigneeIds: [...this.editableAssigneeIds],
        productionResourceType: undefined
      },
      errors: []
    }
    this.enforcedLocality = miscUtil.getEnforcedLocality()
  }

  isViableEmployee = (ee) => {
    // include only internal emps with at least 1 active contract
    return !ee.external && ee.contracts?.some(c => !((c.period?.start && moment(c.period.start).isAfter(moment())) || (c.period?.end && moment(c.period.end).isBefore(moment()))))
  }

  handleOnSubmit = () => {
    // don't add new positions when some plugins prevent it
    if (!this.props.positionId && this.props.isPreventedByPlugin('editJobPositions')) {
      notification.warn({ code: 'functionBlockedByPlugin' })
      return
    }

    const form = this.state.form
    const position = this.getPosition()
    const name = form.name || (position && position.name)

    const errors = [
      valUtil.required(name, 'name'),
      form.productionResourceType &&
      (!form.productionResourceType.name || form.productionResourceType.name === '')
        ? valUtil.required(form.productionResourceType.name, 'productionResourceTypeName')
        : null
    ].filter((e) => !!e)

    if (errors.length > 0) {
      this.setState({ errors })
      return
    }

    const data = {
      name: form.name,
      color: form.color,
      productionResourceType: form.productionResourceType,
      assigns: form.assigneeIds.filter((id) => !this.editableAssigneeIds.includes(id)),
      unAssigns: this.editableAssigneeIds.filter((id) => !form.assigneeIds.includes(id))
    }

    this.props
      .savePosition(this.props.positionId, data)
      .then((result) => {
        if (result?.error == null && this.props.positionId) {
          notification.success({ message: 'SUCCESS_MESSAGE.success' })
        }
        if (this.props.afterSubmit) this.props.afterSubmit(result)
      })
      .catch((error) => {
        notification.error({ message: 'ERR_MESSAGE.editFailed' })
        console.log(error)
      })
    this.props.onClose()
  }

  handleOnFieldChange = (key, value) => {
    const update = {}
    update[key] = value
    this.setState({
      form: Object.assign({}, this.state.form, update),
      errors: errorUtil.filterField(key, this.state.errors)
    })
  }

  setAssigneeIds = (ids) => {
    if (this._isMounted) {
      this.setState((state) => ({
        ...state,
        form: { ...state.form, assigneeIds: ids }
      }))
    }
  }

  handleUnAssign = (id) => {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        assigneeIds: state.form.assigneeIds.filter((assigneeId) => assigneeId !== id)
      }
    }))
  }

  getPosition = () => {
    const { positionId, positions } = this.props
    if (!positionId || !positions) return null
    return positions.find((pos) => pos.id === positionId)
  }

  getAssigneeIds = () => this.state.form.assigneeIds
  getAllAssigneeIds = () => [...this.state.form.assigneeIds, ...this.notEditableAssigneeIds]
  getAllAssignedEmployees = () => this.getAllAssigneeIds().map(id => {
    const employee = this.employees.find((emp) => emp.id === id)
    return employee || { id }
  })

  setData = () => {
    const { employees, positionId } = this.props
    this.employees = Object.values(this.props.employees).filter(
      (employee) => !employee.external
    )

    const initialAssigneeIds = []
    if (positionId) {
      Object.values(employees)
        .filter(ee => this.isViableEmployee(ee))
        .forEach(ee => {
          if (miscUtil.getPositionsOnRole(ee, moment()).some(p => p.id === positionId)) {
            initialAssigneeIds.push(ee.id)
          }
        })
    }

    this.editableAssigneeIds = initialAssigneeIds.filter((assigneeId) =>
      this.employees.some((employee) => employee.id === assigneeId)
    )
    this.notEditableAssigneeIds = initialAssigneeIds.filter((assigneeId) =>
      !this.employees.some((employee) => employee.id === assigneeId)
    )

    this.setAssigneeIds([...this.editableAssigneeIds])
  }

  componentDidMount () {
    this._isMounted = true
    const { positions } = this.props
    const { form } = this.state
    const COLORS = colorUtil.getDefaultColors()
    const UNUSED_COLORS = [...COLORS].filter(
      (col) => positions && !positions.find((pos) => pos.color === col)
    )

    if (!this.props.employees || Object.keys(this.props.employees).length === 0) {
      this.props.loadEmployees()
    }
    if (!this.props.positions || this.props.positions.length === 0) {
      this.props.loadPositions()
    }

    // get the productionResourceType value from position to form
    const position = this.getPosition()

    if (position && position.productionResourceType) {
      this.setState((s) =>
        Object.assign({}, s, {
          form: Object.assign({}, s.form, {
            productionResourceType: position.productionResourceType
          })
        })
      )
    }

    // if we're creating a new position, pick a random unused color for it (or any random, if they are all already used)
    if (!position && !form.color) {
      const cols = UNUSED_COLORS.length ? UNUSED_COLORS : COLORS
      this.setState((s) =>
        Object.assign({}, s, {
          form: Object.assign({}, s.form, {
            color: cols[Math.floor(Math.random() * cols.length)]
          })
        })
      )
    }
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.employees !== this.props.employees ||
      prevProps.positions !== this.props.positions
    ) {
      this.setData()
    }
  }

  render () {
    const { isPreventedByPlugin, workspace } = this.props
    const form = this.state.form
    const errors = this.state.errors.concat(this.props.errors)
    const position = this.getPosition()

    const capacityPlugin = workspace?.plugins?.find((p) => p.plugin === 'capacityplanning' && p.enabled)

    const openDeleteConfirmationModal = () => {
      this.props.setModal('confirm', {
        title: t('REMOVE_POSITION_MODAL_TITLE', {
          x: formUtil.getValue('name', position, form)
        }),
        text: t('REMOVE_POSITION_MODAL_TEXT') + '\n' + t('REMOVE_POSITION_MODAL_WARNING'),
        confirmLabel: t('DELETE_POSITION'),
        onConfirm: () => {
          this.props.deletePosition(this.props.positionId)
          this.props.onClose()
        },
        cancelLabel: t('CANCEL'),
        onCancel: () => {},
        overSidebar: true
      })
    }

    return (
      <Layout
        title={t(position ? 'EDIT_JOB_POSITION' : 'ORG_SP_WORKSPACES_POSITIONS_ADD_NEW')}
        helpText={t('FOR_EVERY_POSITION_YOU_ADD')}
        onClose={this.props.onClose}
        actionIcon={
          !isPreventedByPlugin('editJobPositions') && position ? (
            <Tooltip
              text={t('DELETE')}
              position={Tooltip.POSITIONS.BOTTOM}
              anchor={
                <Icon
                  testid='delete-position-button'
                  ico={Icon.ICONS.delete}
                  color={Icon.COLORS.RED}
                  size={Icon.SIZES.SMALL}
                  onClick={() => openDeleteConfirmationModal()}
                />
              }
            />
          ) : undefined
        }
        primaryButton={{
          label: position ? t('SAVE') : t('CREATE'),
          handleClick: this.handleOnSubmit
        }}
        secondaryButton={{
          label: t('CLOSE'),
          handleClick: this.props.onClose
        }}
      >
        <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_16}>
          <Input
            testid='position-name-input'
            size={Input.SIZES.FULL_WIDTH}
            autoFocus
            value={formUtil.getValue('name', position, form)}
            hasError={errorUtil.hasField('name', errors)}
            type='text'
            label={t('NAME_V2')}
            placeholder={t('POSITION_NAME_PLACEHOLDER')}
            onChange={(value) => this.handleOnFieldChange('name', value)}
            // prevent editation when okbase plugin is enabled
            disabled={isPreventedByPlugin('editJobPositions') && position}
            onClickActiveWhenDisabled={
              isPreventedByPlugin('editJobPositions') && position
            }
            onClick={
              isPreventedByPlugin('editJobPositions') && position
                ? () => {
                  notification.warn({ code: 'functionBlockedByPlugin' })
                }
                : undefined
            }
          />
          <Spacing size={Spacing.SIZES.SIZE_10} type={Spacing.TYPES.VERTICAL} />
          <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
            {t('COLOR')}
          </Text>
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.VERTICAL} />
          <ColorPicker
            value={formUtil.getValue('color', position, form)}
            onChange={(value) => this.handleOnFieldChange('color', value)}
          />
          <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL} />
          <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>
            {t('ASSIGNED_EMPLOYEES')}
          </Text>
          {isPreventedByPlugin('editJobPositions') === false && isPreventedByPlugin('assignJobPositions') === false && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL} />
              <Dropdown
                testid='positon-employee-select'
                label={null}
                searchable
                size={Dropdown.SIZES.FULL_WIDTH}
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.OUTLINED}
                options={sortUtil
                  .sortEmployees(this.employees)
                  .filter(ee => this.isViableEmployee(ee))
                  .map((emp) => {
                    return {
                      value: emp.id,
                      label: emp.name
                    }
                  })}
                value={this.employees
                  .filter((employee) => this.getAssigneeIds().includes(employee.id))
                  .map((employee) => ({ value: employee.id, label: employee.name }))}
                placeholder={<div>{t('SELECT_EMPLOYEE')}</div>}
                onChange={(selected) => {
                  this.setAssigneeIds(selected.map((option) => option.value))
                }}
              />
            </>
          )}
          {this.getAllAssignedEmployees().length ? (
            <>
              <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
              <EmployeeList
                employees={this.getAllAssignedEmployees()}
                onRemove={(empId) => this.handleUnAssign(empId)}
                canBeRemoved={(employee) =>
                  isPreventedByPlugin('editJobPositions') === false &&
                  isPreventedByPlugin('assignJobPositions') === false &&
                  (this.enforcedLocality === false || this.employees.some(emp => emp.id === employee.id))}
              />
            </>
          ) : null}
          {capacityPlugin && (
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14}>
              <CapacityPlanning
                disabled={isPreventedByPlugin('editJobPositions')}
                toggle={{
                  value: !!form.productionResourceType,
                  handleChange: () =>
                    this.setState((state) =>
                      state.form.productionResourceType
                        ? {
                          ...state,
                          form: { ...state.form, productionResourceType: null }
                        }
                        : {
                          ...state,
                          form: {
                            ...state.form,
                            productionResourceType: {
                              name: '',
                              defaultHourlyProductivity: 10
                            }
                          }
                        }
                    )
                }}
                nameInput={{
                  value: form.productionResourceType
                    ? form.productionResourceType.name
                    : '',
                  hasError: errorUtil.hasField('productionResourceTypeName', errors),
                  handleChange: (name) => {
                    this.setState((state) => ({
                      ...state,
                      form: {
                        ...state.form,
                        productionResourceType: {
                          ...state.form.productionResourceType,
                          name
                        }
                      }
                    }))
                  }
                }}
                productivityInput={{
                  value: form?.productionResourceType?.defaultHourlyProductivity,
                  handleChange: (hours) => {
                    this.setState((state) => ({
                      ...state,
                      form: {
                        ...state.form,
                        productionResourceType: {
                          ...state.form.productionResourceType,
                          defaultHourlyProductivity:
                            hours && hours !== '' ? parseFloat(hours) : null
                        }
                      }
                    }))
                  },
                  handleBlur: (hours) => {
                    if (isNaN(parseFloat(hours))) {
                      this.setState((state) => ({
                        ...state,
                        form: {
                          ...state.form,
                          productionResourceType: {
                            ...state.form.productionResourceType,
                            defaultHourlyProductivity: 0
                          }
                        }
                      }))
                    }
                  }
                }}
              />
            </Spacing>
          )}
        </Spacing>
      </Layout>
    )
  }
}

export const PositionEditor = connect(_PositionEditor)
