import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import {
  calendarUtil,
  notification,
  miscUtil,
  useMutate,
  useAccessRole
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import {
  replyActionId,
  requestRoleStats,
  requestTimeOffAttachments
} from '@app/request'
import { TimeOffCreateHalfDayOverShift } from '@app/queries'
import {
  Input,
  Modal,
  RadioGroup,
  Separator,
  Spacing
} from '@ui'

import {
  Attachments,
  ExtraHeaderButtons,
  FooterButtons,
  HeaderContent,
  RepeatTimeOff,
  IncludeTimeOffInSelectedPeriod,
  DataSection,
  OverviewTable
} from './components'
import connect from './connect'

export const EditUnavailability = connect(({
  newAvailabilityOrTimeOff,
  workspace,
  day,
  userId,
  unavId,
  availabilities,
  timeOffs,
  workspaceId,
  auth,
  me,
  isLoading,
  employees,
  hour,
  isEmployeeCalendar,
  isAttendancePage,
  setModal,
  setCalendarMultiSelect,
  organizationId,
  isPluginEnabled,
  isPreventedByPlugin,
  processRequest,
  acceptOrDenyRequest,
  afterSubmit,
  createTimeOffRequest,
  close,
  setAvailabilityOrTimeOff,
  deleteUnavailability,
  shifts,
  employeeCalendarShifts
}) => {
  const [state, setState] = useState({
    detailsState: {},
    allDayRadio: 'full',
    errors: [],
    userRoleStats: null,
    isEdited: false,
    halfDay: null
  })
  const details = state.detailsState

  const isAvailabilityOrUnavailability = ['availability', 'unavailability'].includes(details.categoryId) || typeof details.categoryId === 'undefined'
  const { canRead, canWrite } = useAccessRole()

  const { mutation: timeOffCreateHalfDayOverShift } = useMutate(TimeOffCreateHalfDayOverShift, {
    variables: {},
    onCompleted: () => {
      setModal(null)
      notification.success({ code: 'success' })

      // if an 'afterSubmit' function was provided, we'll call it here
      if (afterSubmit) afterSubmit()
    }
  })

  const userRoleStats = state.userRoleStats
  const yearVacation = userRoleStats && (
    workspace?.displayVacationsAsDays
      ? (userRoleStats?.yearVacationDays + userRoleStats?.yearVacationRemainingDays)
      : (userRoleStats?.yearVacationMinutes + userRoleStats?.yearVacationRemainingMinutes)
  )
  const yearVacationRemaining = userRoleStats && (
    workspace?.displayVacationsAsDays
      ? userRoleStats?.yearVacationRemainingDays
      : userRoleStats?.yearVacationRemainingMinutes
  )

  const shiftSource = employeeCalendarShifts || shifts
  const shiftsInDay = shiftSource.filter((shift) => shift.userId === userId && moment(details?.period?.start).isSame(shift?.period?.start, 'day'))
  // info about user's remaining days of this unav category in the year
  const unavDaysInYear = userRoleStats?.yearUnavailabilities && userRoleStats.yearUnavailabilities.find(u => u.categoryId === details.categoryId)
  let employeeOnUnav = details ? employees[details.userId] : {}
  if (details.userId && (!employeeOnUnav || !employeeOnUnav.id)) {
    if (me && me.id === details.userId) employeeOnUnav = me
  }

  // disable editing of timeOffs for employee
  let disabledEditation = false
  if (isEmployeeCalendar && !newAvailabilityOrTimeOff &&
    calendarUtil.isEventAvailabilityOrTimeOff(details) === 'timeOff') {
    disabledEditation = 'disabled-employee-hard-unav'
  }
  // disable editation if the unav is in a locked period
  if (!disabledEditation) {
    if (details.period && details.period.start && miscUtil.isMyCalendarLocked(details.period.start, workspace)) disabledEditation = 'disabled-due-to-locked-period'
  }

  const unavCategories = calendarUtil.getAvailAndTimeOffCategoriesOnWS(workspace)
  let availableCategories = unavCategories
    .filter(cat => (cat.employeeCanRequest !== false || !isEmployeeCalendar))
    .map(cat => ({
      value: cat.id || (cat.available ? 'availability' : 'unavailability'),
      label: cat.name,
      isVacation: cat.vacation,
      archived: cat.archived,
      groupTitle: cat.id ? t('TIMEOFFS') : t('TIME_PREFERENCES')
    }))

  availableCategories.sort((a, b) => ('' + a.label).localeCompare(b.label))

  const timeOffCategories = availableCategories.filter((cat) => cat.groupTitle === t('TIMEOFFS'))
  const timePreferencesCategories = availableCategories.filter((cat) => cat.groupTitle === t('TIME_PREFERENCES'))

  const addNewCategory = (isEmployeeCalendar || isPreventedByPlugin('editUnavailabilityCategories')) ? []
    : canWrite(PERMISSIONS.WORKSPACE.TIMEOFFCATEGORIES) ? [{
      label: '+ ' + t('UNAV_ADD_NEW_CATEGORY'),
      value: 'newCategory',
      archived: false,
      isVacation: false,
      groupTitle: t('TIMEOFFS')
    }] : []

  availableCategories = timePreferencesCategories.concat(timeOffCategories, addNewCategory)

  const employeeContract = Object.values(employees).find((employee) => employee.id === details.userId)
  const isMultipleDays = moment.duration(moment(details?.period?.end).diff(details?.period?.start)).asDays() > 1

  // decide if we're adding timeOff straight away, or just creating a timeOff request
  const isRequestingAbsence = isEmployeeCalendar && !isAvailabilityOrUnavailability && newAvailabilityOrTimeOff

  // if we're approving/denying a timeOff request, don't include (un)availability options in the dropdown
  if (processRequest) {
    availableCategories = availableCategories.filter((cat) => cat.value !== 'unavailability' && cat.value !== 'availability')
  }

  if (!newAvailabilityOrTimeOff && details.categoryId) {
    availableCategories = availableCategories.filter((cat) => !!cat.value)
  }

  function getDefaultDetails (day, userId) {
    const dayMoment = day ? moment(day) : moment()
    if (!userId) checkUserNotSelected()
    let defaultDetails = null
    if (newAvailabilityOrTimeOff === 'availability' || newAvailabilityOrTimeOff === 'unavailability') {
      // default values for availability object
      defaultDetails = {
        id: null,
        userId: userId,
        period: {
          start: dayMoment.startOf('day').format(),
          end: dayMoment.endOf('day').format()
        },
        categoryId: newAvailabilityOrTimeOff,
        available: (newAvailabilityOrTimeOff === 'availability'),
        note: null,
        recurrence: null,
        workMinutesInclusion: window.localStorage.getItem('ds-selected-unavailability-work-minutes-inclusion') ?? 'everyDay',
        workMinutesBy: 'shift'
      }
    } else {
      // default values for timeOff object
      const unavCategories = calendarUtil.getAvailAndTimeOffCategoriesOnWS(workspace)
      defaultDetails = {
        id: null,
        userId: userId,
        period: {
          start: dayMoment.startOf('day').format(),
          end: dayMoment.endOf('day').format()
        },
        contractId: null,
        categoryId: newAvailabilityOrTimeOff ? unavCategories.find((unav) => !!unav.id)?.id : null,
        available: null,
        note: null,
        recurrence: null,
        workMinutesInclusion: window.localStorage.getItem('ds-selected-unavailability-work-minutes-inclusion') ?? 'everyDay',
        workMinutesBy: 'shift'
      }
    }
    setState((prev) => ({ ...prev, detailsState: defaultDetails }))
  }
  async function loadDetails () {
    if (!unavId && !userId) return

    // unav ID was passed - editing existing unav
    if (!newAvailabilityOrTimeOff && unavId) {
      let evtObj = availabilities.find(evt => evt.id === unavId)
      let attachments = []
      if (!evtObj) {
        const res = await requestTimeOffAttachments({
          timeOffId: unavId,
          workspaceId: (canRead(PERMISSIONS.CALENDAR) || canRead(PERMISSIONS.BACKOFFICE.ATTENDANCE) || canRead(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS)) ? workspaceId : undefined,
          userId: (!canRead(PERMISSIONS.CALENDAR) && !canRead(PERMISSIONS.BACKOFFICE.ATTENDANCE) && !canRead(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS)) ? me.id : undefined
        }, auth)
        if (!res.error) {
          attachments = res
        }
        evtObj = timeOffs.find(evt => evt.id === unavId)
        if (evtObj?.requestPendingAction) {
          const action = await replyActionId({ actionId: evtObj.requestPendingAction }, auth)
          evtObj = {
            ...evtObj,
            period: action?.data?.period || evtObj.period
          }
        }
      }

      if (evtObj && evtObj.period && evtObj.period.start && evtObj.period.end) {
        setState((s) => ({
          ...s,
          detailsState: {
            ...evtObj,
            attachments: attachments ? attachments.map((item) => item.file) : []
          },
          allDayRadio: evtObj.periodType === 'halfDay'
            ? 'half'
            : evtObj.periodType === 'wholeDay'
              ? 'full'
              : evtObj.periodType === 'partDay'
                ? 'part'
                : isWholeDay(evtObj.period.start, evtObj.period.end) // fallback for availabilities, where periodType is undefined
                  ? 'full'
                  : 'part'
        }))

        // load role stats for the unav's user
        loadUserRoleStats(evtObj.userId)
      }
    }

    // user ID was passed - adding a new one
    if (newAvailabilityOrTimeOff && userId) {
      // load role stats for the given userId
      loadUserRoleStats(userId)
    }
  }

  function loadUserRoleStats (usrId) {
    const referenceDate = details?.period?.start
      ? details.period.start : day
        ? moment(day) : moment().startOf('day')

    if (!usrId) {
      setState((s) => ({ ...s, userRoleStats: null }))
      return false
    }

    requestRoleStats(
      {
        workspace: workspaceId,
        user: usrId,
        referenceDate: moment(referenceDate)
      }, auth).then((res) => {
      setState((s) => ({ ...s, userRoleStats: res }))
    })
  }

  function isWholeDay (start, end) {
    return moment(start).format('HH:mm') === '00:00' &&
      (moment(end).format('HH:mm') === '00:00' || moment(end).format('HH:mm') === '23:59')
  }

  function canSave () {
    if (!details) return false
    if (newAvailabilityOrTimeOff) {
      if (!details.userId) return false
      if (!details.period || !details.period.start || !details.period.end) return false
      if (!moment(details.period.start).isValid() || !moment(details.period.end).isValid()) return false
      if (moment(details.period.end).isBefore(details.period.start)) return false
    }
    if (state.errors.includes('repeat-over-limit')) return false
    return true
  }

  function setVal (key, val) {
    // deselect 'half day' if we just switched to unavailability
    if (key === 'categoryId' && typeof val === typeof null) {
      if (state.halfDay) {
        setState(s => Object.assign({}, s, { allDayRadio: 'part', isEdited: true }))
      }
    }

    // remove the 'end-before-start' error if we're changing the end or start to correct value
    if (key === 'period') checkEndBeforeStart(val)

    // if employee was switched, or unav start's year was changed, load given user's role stats w.r.t. to the start date
    if (key === 'userId' ||
      (key === 'period' && val.start && !moment(val.start).isSame(details.period.start, 'year'))
    ) {
      loadUserRoleStats(key === 'userId' ? val : details.userId)
    }
    // actually set the value
    setState((prevState) => ({
      ...prevState,
      detailsState: {
        ...prevState.detailsState,
        [key]: val
      },
      isEdited: true
    }))
  }

  const handleToggleAllDay = (value) => {
    const newDetailsState = { ...details }
    const halfDay = value === 'half' ? 'start' : null

    const newPeriod = (value === 'full') // full day
      ? {
        start: moment(details.period.start).clone().set({ hour: 0, minute: 0 }).format(),
        end: moment(details.period.start).clone().set({ hour: 0, minute: 0 }).endOf('day').format()
      }
      : (value === 'part') // part of day
        ? {
          start: moment(details.period.start).clone().set({
            hour: (hour || 8),
            minute: 0
          }).format(),
          end: moment(details.period.start).clone().set({
            hour: hour ? ((Math.min(hour + 9, 24))) : 17,
            minute: 0
          }).format()
        }
        : cutTimeoffPeriod(halfDay) // half day

    newDetailsState.period = newPeriod
    setState((s) => ({ ...s, allDayRadio: value, detailsState: newDetailsState, halfDay, isEdited: true }))
  }

  function requestAbsence () {
    const detailsState = { ...details }
    // add or update the unavailability
    createTimeOffRequest({
      workspace: workspaceId,
      user: detailsState.userId,
      category: detailsState.categoryId,
      period: detailsState.period,
      periodType: state.allDayRadio === 'full'
        ? 'wholeDay'
        : state.allDayRadio === 'half'
          ? 'halfDay'
          : 'partDay',
      atTheStartOfShift: state?.halfDay === 'start',
      note: detailsState.note,
      attachments: detailsState?.attachments ? detailsState.attachments.map((att) => att.id) : [],
      shift: (state.allDayRadio === 'half' && shiftsInDay?.length)
        ? shiftsInDay[0]?.id
        : undefined
    }).then((res) => {
      close()
      if (!res.error) {
        notification.success({ code: 'requestCreated' })
      }
    })
  }
  function cutTimeoffPeriod (opts = {}) {
    const shift = shiftsInDay[0]
    const pauseDuration = shiftsInDay[0].duration - shiftsInDay[0].stats.reduce((sum, stats) => sum + stats.workMinutes, 0)
    const currentContractType = isEmployeeCalendar
      ? (me?.roles?.length ? workspace.contractTypes.find((contract) => contract.id === me.roles[0].contractNew?.type) : null)
      : workspace.contractTypes.find((contract) => contract.id === employees[userId].contractType)
    const contractPauses = currentContractType?.rules.pauses
    const halfDay = opts.halfDayOverride || state.halfDay

    if (halfDay === 'start' || typeof halfDay === typeof null) {
      let newEnd = moment(shift.period.start).add((shift.stats.reduce((sum, stats) => sum + stats.workMinutes, 0) / 2), 'minutes').format()
      if (contractPauses && shift.duration >= 2 * (contractPauses.afterWorkTime + contractPauses.duration)) {
        newEnd = moment(newEnd).subtract(pauseDuration / 2, 'minutes').format()
      }
      return {
        start: shift.period.start,
        end: newEnd
      }
    }
    if (halfDay === 'end') {
      let newStart = moment(shift.period.start).add(shift.stats.reduce((sum, stats) => sum + stats.workMinutes, 0) / 2, 'minutes').format()
      if (contractPauses && shift.duration >= 2 * (contractPauses.afterWorkTime + contractPauses.duration)) {
        newStart = moment(newStart).add(pauseDuration / 2, 'minutes').format()
      }
      return {
        start: newStart,
        end: moment(shift.period.end).subtract(pauseDuration, 'minutes')
      }
    }
  }
  function handleSave () {
    const detailsState = { ...details }

    if (state.allDayRadio === 'half' && newAvailabilityOrTimeOff) {
      const shift = shiftsInDay[0]
      timeOffCreateHalfDayOverShift({
        variables: {
          user: detailsState?.userId,
          attachments: detailsState?.attachments ? detailsState.attachments.map((item) => item.id) : [],
          shift: shift?.id,
          category: detailsState?.categoryId,
          note: detailsState?.note,
          atTheStartOfShift: state?.halfDay === 'start'
        }
      })
    } else {
      // add or update the unavailability
      setAvailabilityOrTimeOff({
        evts: [{
          ...detailsState,
          periodType: state.allDayRadio === 'full'
            ? 'wholeDay'
            : state.allDayRadio === 'half'
              ? 'halfDay'
              : 'partDay',
          user: detailsState.userId,
          attachments: detailsState?.attachments ? detailsState.attachments.map((item) => item.id) : []
        }],
        unavId: newAvailabilityOrTimeOff ? null : unavId
      }).then((result) => {
        if (
          !isEmployeeCalendar &&
          result &&
          result.conflictingShifts &&
          result.conflictingShifts.length
        ) {
          setModal('timeoff-conflict', {
            conflictingShifts: result.conflictingShifts,
            resultTimeOffs: result.timeOffs,
            halfDayType: state.halfDay
          })
        } else {
          setModal(null)
          notification.success({ code: 'success' })
        }

        // if an 'afterSubmit' function was provided, we'll call it here
        if (afterSubmit) afterSubmit()
      })
    }
  }
  function checkEndBeforeStart (val) {
    const errKey = 'end-before-start'
    let errors = state.errors || []
    const period = val || details.period
    if (state.errors && moment(period.end).isBefore(period.start)) {
      if (!errors.includes(errKey)) errors.push(errKey)
    } else {
      errors = errors.filter((item) => item !== errKey)
    }
    setState((prev) => ({ ...prev, errors }))
  }

  const wsUnavCategory = workspace.unavailabilityCategories?.find((unav) => unav.id === details.categoryId)
  function checkUserNotSelected (overrideUserId) {
    // add the error 'user-not-selected' if user isn't selected
    if (state.errors && !details.userId) {
      const errors = state.errors || []
      if (!errors.includes('user-not-selected')) errors.push('user-not-selected')
      setState((prev) => ({ ...prev, errors }))
    }

    // remove 'user-not-selected' error if user is selected
    if (Array.isArray(state.errors) && state.errors.includes('user-not-selected') && (overrideUserId || !!details.userId)) {
      setState((prev) => ({
        ...prev,
        errors: state.errors.filter((error) => error !== 'user-not-selected')
      }))
    }
  }
  const allDayOptions = [
    {
      value: 'full',
      label: t('ALL_DAY')
    },
    (((shiftsInDay.length === 1 && !isAvailabilityOrUnavailability) || state.allDayRadio === 'half') && {
      value: 'half',
      label: t('UNAV_HALF_DAY')
    }),
    // TODO Create selector for this
    ((workspace?.country !== 'SK' || (workspace?.country === 'SK' && !wsUnavCategory?.vacation)) && {
      value: 'part',
      label: t('MA_PART_OF_DAY')
    })
  ].filter((item) => !!item)

  const title = () => {
    if (isAvailabilityOrUnavailability) {
      if (details?.available) return t('AVAILABILITY')
      return t('UNAV')
    }
    return t('TIMEOFF')
  }

  useEffect(() => {
    // reset the state if creating a new unavailability
    if (newAvailabilityOrTimeOff) getDefaultDetails(day, userId)
  }, [availabilities, timeOffs])
  useEffect(() => {
    // load the unav details if not creating a new one
    if (!newAvailabilityOrTimeOff) loadDetails()
    // load the role stats for pre-selected user
    if (userId) loadUserRoleStats(userId)
  }, [availabilities, timeOffs, shiftsInDay.length])

  const warningsHTML = (newAvailabilityOrTimeOff ? null : miscUtil.getEventWarningsForEditModal(details, isPluginEnabled('laborlaw')))

  return (
    <Modal
      size={Modal.SIZES.L}
      isLoading={isLoading || !details.period}
      headerContent={
        <HeaderContent
          isRequestingAbsence={isRequestingAbsence}
          newAvailabilityOrTimeOff={newAvailabilityOrTimeOff}
          isEmployeeCalendar={isEmployeeCalendar}
          details={details}
          userId={userId}
          isAttendancePage={isAttendancePage}
          afterSubmit={afterSubmit}
          isPluginEnabled={isPluginEnabled}
          title={title()}
          setModal={setModal}
          day={day}
          hour={hour}
        />
      }
      // Extra actions - DELETE or COPY
      extraHeaderButtons={
        [
          <ExtraHeaderButtons
            key={0}
            newAvailabilityOrTimeOff={newAvailabilityOrTimeOff}
            disabledEditation={disabledEditation}
            processRequest={processRequest}
            day={day}
            isEmployeeCalendar={isEmployeeCalendar}
            details={details}
            setCalendarMultiSelect={setCalendarMultiSelect}
            setModal={setModal}
            deleteUnavailability={() => {
              deleteUnavailability(
                details,
                isAvailabilityOrUnavailability,
                afterSubmit
              )
            }}
          />]
      }
      footerContent={
        <FooterButtons
          details={details}
          errors={state.errors}
          disabledEditation={disabledEditation}
          canSave={canSave()}
          close={close}
          setModal={setModal}
          isEdited={state.isEdited}
          afterSubmit={afterSubmit}
          isRequestingAbsence={isRequestingAbsence}
          processRequest={processRequest}
          shifts={shifts}
          checkEndBeforeStart={checkEndBeforeStart}
          checkUserNotSelected={checkUserNotSelected}
          requestAbsence={requestAbsence}
          newAvailabilityOrTimeOff={newAvailabilityOrTimeOff}
          onSave={handleSave}
          acceptOrDenyRequest={acceptOrDenyRequest}
          allDayRadio={state.allDayRadio}
        />
      }
    >
      {
        // Warnings
        warningsHTML ? (
          <>
            <Spacing size={Spacing.SIZES.SIZE_16} type={(Spacing.TYPES.BOTH)}>
              {warningsHTML}
            </Spacing>
            <Separator side={Separator.SIDES.BOTTOM} size={Separator.SIZES.FULL_WIDTH} />
          </>
        ) : null
      }
      <Spacing size={Spacing.SIZES.SIZE_16} type={(Spacing.TYPES.BOTH)}>
        <DataSection
          details={details}
          isEmployeeCalendar={isEmployeeCalendar}
          newAvailabilityOrTimeOff={newAvailabilityOrTimeOff}
          employeeOnUnav={employeeOnUnav}
          checkUserNotSelected={checkUserNotSelected}
          setVal={setVal}
          availableCategories={availableCategories}
          unavId={unavId}
          errors={state.errors}
          close={close}
          userRoleStats={userRoleStats}
          unavDaysInYear={unavDaysInYear}
          yearVacationRemaining={yearVacationRemaining}
          yearVacation={yearVacation}
          allDayRadio={state.allDayRadio}
          disabledEditation={disabledEditation}
          wsDisplayVacationsAsDays={Boolean(workspace?.displayVacationsAsDays)}
          employees={employees}
          handleHalfDayChange={({ value }) => {
            setState({
              ...state,
              halfDay: value,
              detailsState: Object.assign({}, state.detailsState, isEmployeeCalendar ? { period: cutTimeoffPeriod({ halfDayOverride: value }) } : undefined)
            })
          }}
          halfDay={state.halfDay}
          workspace={workspace}
        />
        <Spacing size={Spacing.SIZES.SIZE_10} />
        <RadioGroup
          options={allDayOptions}
          onChange={handleToggleAllDay}
          disabled={disabledEditation}
          value={state.allDayRadio}
        />
        <Spacing size={Spacing.SIZES.SIZE_20} />
        {!isAvailabilityOrUnavailability && (canRead(PERMISSIONS.CALENDAR) || canRead(PERMISSIONS.BACKOFFICE.ATTENDANCE) || canRead(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS)) && (
          <IncludeTimeOffInSelectedPeriod
            isMultipleDays={isMultipleDays}
            setVal={setVal}
            endDate={details.period?.end}
            workMinutesBy={details.workMinutesBy}
            workMinutes={details.workMinutes}
            organizationId={organizationId}
            inclusion={details.workMinutesInclusion}
            selectedCategory={unavCategories.find((con) => con.id === details.categoryId)}
            selectedContract={employeeContract}
            disabledEditation={disabledEditation}
            halfDay={state.halfDay}
          />
        )}
      </Spacing>
      <Separator side={Separator.SIDES.BOTTOM} size={Separator.SIZES.FULL_WIDTH} />
      <Spacing size={Spacing.SIZES.SIZE_16} type={(Spacing.TYPES.BOTH)}>
        {!isAvailabilityOrUnavailability && (
          <Attachments
            attachments={details?.attachments || []}
            setVal={setVal}
            employees={employees}
            workspaceId={workspaceId}
            auth={auth}
          />
        )}
        <Input
          testid='note-input'
          size={Input.SIZES.LARGE}
          label={t('NOTE')}
          type='text'
          placeholder={t('NOTE')}
          onChange={(v) => setVal('note', v)}
          value={details.note}
          disabled={disabledEditation}
        />
        {!state.halfDay && (
          <>
            <Spacing size={Spacing.SIZES.SIZE_16} />
            <RepeatTimeOff
              isMultipleDays={isMultipleDays}
              day={details?.period?.start}
              onChange={(val) => {
                setVal('recurrence', val)
                setVal('recurrenceUpdateFollowing', true)
              }}
              value={details?.recurrence}
              setError={(v) => setState((prev) => ({ ...prev, errors: [...state.errors, v] }))}
              removeError={(v) => setState({
                ...state,
                errors: state.errors.filter((error) => error !== 'repeat-over-limit')
              })}
              hasError={state.errors.includes('repeat-over-limit')}
              title={title}
              disabledEditation={disabledEditation}
            />
          </>)}
        {!isAvailabilityOrUnavailability && (canRead(PERMISSIONS.CALENDAR) || canRead(PERMISSIONS.BACKOFFICE.ATTENDANCE) || canRead(PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS)) && !isEmployeeCalendar && (
          <OverviewTable
            period={details.period}
            timeOffs={timeOffs}
            unavCategories={workspace.unavailabilityCategories}
            employees={employees}
          />
        )}
      </Spacing>
    </Modal>
  )
}
)

EditUnavailability.propTypes = {}
EditUnavailability.defaultProps = {}
